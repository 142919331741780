import React from 'react'
import { locale } from '../../../src/common/i18n'

const RevisionesCA = () => null

const RevisionesES = () => (
  <React.Fragment>
    <p>
      En El Paso 2000 somos expertos en el cambio de aceite. Te ofrecemos{' '}
      <strong>cuatro packs</strong> de{' '}
      <strong>cambio de aceite y filtros</strong> con los que trataremos todas
      las necesidades de tu vehículo. Nuestros packs están diseñados tanto para
      coches diésel como gasolina, donde realizamos: cambio de aceite, nivelado
      de líquido refrigerante y nivelado de líquido limpiaparabrisas.
    </p>
    <p style={{ marginTop: '1em' }}>
      Incorporamos en todos nuestros packs un completo{' '}
      <strong>Test de Seguridad</strong> desarrollado por el equipo de El Paso
      2000. Con esta prueba identificaremos posibles problemas o anomalías que
      pudiera presentar el coche.
    </p>
    <p style={{ marginTop: '1em' }}>
      Hemos diseñado estos packs de cambio de aceite y filtros para adaptarse a
      las necesidades de toda clase de coches y conductores, sin importar la
      antigüedad del automóvil.
    </p>
  </React.Fragment>
)

const RevisionesContents = () =>
  locale === 'es' ? <RevisionesES /> : <RevisionesCA />

export default RevisionesContents
