import { navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { SeoData } from '../../types/Seo'
import { MenuPrincipal } from '../../../specific/constants/menu'
import { BreadCrumbItems } from '../breadCrumb/LocalizedBreadCrumb'
import Layout from '../../components/Layout'
import RecomendacionCard from '../../../specific/components/RecomendacionCard/RecomendacionCard'
import ServicesStep from '../../components/revisiones/ServicesStep'
import Title from '../../components/Title'
import {
  resetRevisionLoading,
  setOilSelection,
  setRevisionLoading,
} from '../../context/actions/revisionActions'
import AppContext from '../../context/context'
import { useSelectedMenu } from '../../../specific/helpers/customHooks/useSelectedMenu'
import { locale, t } from '../../i18n'
import logic from '../../logic'
import RevisionesContents from '../../../specific/static/revisiones'
import route from '../../utils/route'
import styles from './revisiones.module.scss'
import ResponsiveSliderBanner from '../../components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import { getImages } from '../../utils/imagesS3'
import Size from '../../utils/media-queries-setup'

interface Props {
  data: {
    seoData: SeoData
  }
}

const idAceite = 1

export default function Revisiones({ data }: Props) {
  const isMobile = Size.useMedia({ mobile: true, tablet: true })

  const {
    dispatch,
    userInfo: { postCode },
    revisionState,
  } = useContext(AppContext)
  useSelectedMenu(MenuPrincipal.REVISIONES)

  const retrieveRevisiones = async (idAceite) => {
    dispatch(setRevisionLoading())
    const revisiones = await logic.getRevisionesList(
      idAceite,
      postCode || revisionState.postCode
    )
    dispatch(resetRevisionLoading())
    dispatch(setOilSelection(idAceite, revisiones))
  }

  useEffect(() => {
    retrieveRevisiones(idAceite)
  }, [])

  const breadCrumItems = [
    { display: t('bread_crumb.inicio'), link: route('index') },
    { display: t('bread_crumb.revisiones') },
  ] as BreadCrumbItems

  const goToRevision = (id: string) => {
    if (id.includes('BASIC')) {
      navigate(route('revisiones.revision-basic'))
    } else if (id.includes('PLUS')) {
      navigate(route('revisiones.revision-plus'))
    } else if (id.includes('PREM')) {
      navigate(route('revisiones.revision-premium'))
    } else if (id.includes('CONF')) {
      navigate(route('revisiones.revision-confort'))
    }
  }

  const banners_mobile = getImages(
    data[`banners_${locale}_mobile`].nodes,
    locale
  )
  const banners_desktop = getImages(
    data[`banners_${locale}_desktop`].nodes,
    locale
  )

  return (
    <Layout
      selector={false}
      seoData={data.seoData}
      breadCrumItems={breadCrumItems}>
      <div className={styles.revisiones_container}>
        <Title text={t('revisiones.revisiones_title')} />
        <div className={styles.left}>
          <ResponsiveSliderBanner
            desktopImages={banners_desktop}
            mobileImages={banners_mobile}
            sizesVariant={'banner_neumaticos'}
          />
          <div className={styles.revision_body_and_list}>
            <div className={styles.revision_body}>
              <RevisionesContents />
            </div>
            <div className={styles.revisiones_list}>
              <ServicesStep
                onSelectRevision={goToRevision}
                textSelectButton={t('revisiones.button_ver')}
              />
            </div>
            {!isMobile && (
              <div className={styles.info_revisiones}>
                {t('preferences.revisionInfo')}
              </div>
            )}
          </div>
        </div>
        <div className={styles.right}>
          <RecomendacionCard recomendacion={t('recomendaciones.revision')} />
        </div>
      </div>
    </Layout>
  )
}
